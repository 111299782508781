import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";

import {
  updateUserFolder,
  updateUserDocument,
  updateUserDraft,
} from "../services/database";
import { sortedArrayFromObject, t } from "../utils";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Collapse from "@material-ui/core/Collapse";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import "moment/locale/fr";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import RequestSecondSignatureForm from "../components/RequestSecondSignatureForm";
import config from '../config.json';

const STAGING_APP_BASE_URL = "https://staging-app.yousign.com";
const PRODUCTION_APP_BASE_URL = "https://webapp.yousign.com";
const signatureUi = "/signature_uis/9cf2c59a-bc36-4005-8509-d4587795eb2a";

moment.locale("fr");

const MyDocuments = (props) => {
  const { userDocuments, userFolders, userDrafts } = props;
  const [currentTab, setCurrentTab] = useState("documents");
  const [documents, setDocuments] = useState([]);
  const [folderDocuments, setFolderDocuments] = useState({});
  const [freeDocuments, setFreeDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [docToFolderDialog, setDocToFolderDialog] = useState({
    isOpen: false,
    docIndex: -1,
    selectedFolder: 0,
  });
  const [openReqSecondSignatureForm, setOpenReqSecondSignatureForm] = useState(
    false
  );

  const closeSecondSignatureForm = () => setOpenReqSecondSignatureForm(false);
  const openSecondSignatureForm = () => setOpenReqSecondSignatureForm(true);

  const closeDocToFolderDialog = () => {
    setDocToFolderDialog({
      isOpen: false,
      docIndex: -1,
      selectedFolder: 0,
    });
  };

  const [folderMenu, setFolderMenu] = useState({
    anchor: null,
    index: -1,
  });

  const [documentMenu, setDocumentMenu] = useState({
    anchor: null,
    index: -1,
    folderIndex: -1,
  });

  const [draftMenu, setDraftMenu] = useState({
    anchor: null,
    index: -1,
  });

  const [signMember, setSignMember] = useState(null);

  useEffect(() => {
    const { getUserDocuments, getUserFolders, getUserDrafts } = props;
    window.scrollTo(0, 0);
    getUserDocuments(false);
    getUserFolders(false);
    getUserDrafts(false);
  }, []);

  useEffect(() => {
    const { location } = props;
    if (location.hash === "#drafts") {
      setCurrentTab("drafts");
    } else {
      setCurrentTab("documents");
    }
    let docs = sortedArrayFromObject(userDocuments, "updated", false);
    setDocuments([...docs]);

    let folds = sortedArrayFromObject(userFolders, "name", true);
    for (let i in folds) {
      folds[i].open = true;
    }
    setFolders([...folds]);

    let drafts = sortedArrayFromObject(userDrafts, "updated", false);
    setDrafts([...drafts]);

    let foldDocs = {};
    let freeDocs = [];
    for (let i in docs) {
      let doc = docs[i];
      if (!!doc.folder) {
        if (!foldDocs[doc.folder]) {
          foldDocs[doc.folder] = [];
        }
        foldDocs[doc.folder].push(doc);
      } else {
        freeDocs.push(doc);
      }
    }
    setFolderDocuments({ ...foldDocs });
    setFreeDocuments([...freeDocs]);
  }, [
    props.userDocuments,
    props.userFolders,
    props.userDrafts,
    props.location.hash,
  ]);

  const toggleFolder = (index) => () => {
    let folds = folders;
    folds[index].open = !folds[index].open;
    setFolders([...folds]);
  };

  const [folderDialog, setFolderDialog] = useState({
    isNew: true,
    name: "",
    isOpen: false,
    id: "",
  });

  const createNewFolder = () => {
    setFolderDialog({
      isNew: true,
      name: "",
      isOpen: true,
      id: "",
    });
  };

  const closeFolderDialog = () => {
    setFolderDialog({
      isOpen: false,
      name: "",
      isNew: true,
      id: "",
    });
  };

  const submitFolderDialog = async () => {
    const { startLoading, stopLoading, getUserFolders } = props;
    closeFolderDialog();
    startLoading("renamingFolder");
    let folder = {
      name: folderDialog.name,
    };
    let result = await updateUserFolder(folder, folderDialog.id);
    if (result.error) {
      showError(result.error);
    }
    stopLoading("renamingFolder");
    getUserFolders();
  };

  const [documentDialog, setDocumentDialog] = useState({
    name: "",
    isOpen: false,
    id: "",
  });

  const closeDocumentDialog = () => {
    setDocumentDialog({
      isOpen: false,
      name: "",
      id: "",
    });
  };

  const submitDocumentDialog = async () => {
    const { startLoading, stopLoading, getUserDocuments } = props;
    closeDocumentDialog();
    startLoading("renamingDoc");
    let result = await updateUserDocument(
      { name: documentDialog.name },
      documentDialog.id
    );
    if (result.error) {
      showError(result.error);
    }
    stopLoading("renamingDoc");
    getUserDocuments();
  };

  const renameDocument = () => {
    const document = getCurrentDocument();
    setDocumentDialog({
      name: document.name,
      isOpen: true,
      id: document.id,
    });
    closeDocumentMenu();
  };

  const openFolderMenu = (index) => (e) => {
    setFolderMenu({
      anchor: e.target,
      index,
    });
  };

  const closeFolderMenu = () => {
    setFolderMenu({
      anchor: null,
      index: -1,
    });
  };

  const renameFolder = () => {
    setFolderDialog({
      isNew: false,
      name: folders[folderMenu.index].name,
      isOpen: true,
      id: folders[folderMenu.index].id,
    });
    closeFolderMenu();
  };

  const deleteFolder = async () => {
    const {
      startLoading,
      stopLoading,
      getUserDocuments,
      getUserFolders,
    } = props;
    startLoading("deletingFolder");
    for (let i in folderDocuments[folders[folderMenu.index].id]) {
      await updateUserDocument(
        null,
        folderDocuments[folders[folderMenu.index].id][i].id
      );
    }
    let result = await updateUserFolder(null, folders[folderMenu.index].id);
    closeFolderMenu();
    if (result.error) {
      showError(result.error);
    }
    stopLoading("deletingFolder");
    getUserDocuments();
    getUserFolders();
  };

  const openDocumentMenu = (docIndex, folderIndex) => (e) => {
    setDocumentMenu({
      anchor: e.target,
      index: docIndex,
      folderIndex,
    });
  };

  const closeDocumentMenu = () => {
    setDocumentMenu({
      anchor: null,
      index: -1,
      folderIndex: -1,
    });
  };

  const deleteDocument = async () => {
    const { startLoading, stopLoading, getUserDocuments } = props;
    startLoading("deleteDocument");
    const document = getCurrentDocument();
    closeDocumentMenu();
    let result = await updateUserDocument(null, document.id);
    if (result.error) {
      showError(result.error);
    }
    stopLoading("deleteDocument");
    getUserDocuments();
  };

  const openDraftMenu = (index) => (e) => {
    setDraftMenu({
      anchor: e.target,
      index,
    });
  };

  const closeDraftMenu = () => {
    setDraftMenu({
      anchor: null,
      index: -1,
    });
  };

  const continueDraft = () => {
    let draft = drafts[draftMenu.index];
    const { history } = props;
    closeDraftMenu();
    history.push({
      pathname: `document-form/${draft.document}`,
      state: { answers: draft.answers, draftId: draft.id },
    });
  };

  const deleteDraft = async () => {
    const { startLoading, stopLoading, getUserDrafts } = props;
    startLoading("deletingDraft");
    let result = await updateUserDraft(null, drafts[draftMenu.index].id);
    if (result.error) {
      showError(result.error);
    }
    closeDraftMenu();
    stopLoading("deletingDraft");
    getUserDrafts();
  };

  const downloadDocument = (format) => () => {
    const document = getCurrentDocument();
    window.open(document.files[format].url, "_blank");
  };

  const RequestSign = async (secondMember, simplyMovePartner = false) => {
    const document = getCurrentDocument();
    closeDocumentMenu()
    return props.onSignUserDocument(document, secondMember, simplyMovePartner);
  };

  const signDocument = () => {
    const document = getCurrentDocument();
    const memberID = document.files["pdf"].signMemberId;
    setSignMember(memberID);
    const src = `${PRODUCTION_APP_BASE_URL}/procedure/sign?members=${memberID}&signatureUi=${signatureUi}`;
    window.location.replace(src);
    // const wnd = window.open("", "Sign", "width=512,height=512");
    // const iframe = `<iframe src=${src} width="100%" height="100%"></iframe>`
    // console.log('iframe:', iframe)
    // wnd.document.write(iframe);
  };

  const promptMoveDocToFolder = () => {
    setDocToFolderDialog({
      isOpen: true,
      docIndex: documentMenu.index,
      folderIndex: documentMenu.folderIndex,
      selectedFolder: 0,
    });
  };

  const moveDocToFolder = async () => {
    const { startLoading, stopLoading, getUserDocuments } = props;
    startLoading("movingDoc");
    let folderId = "";
    if (docToFolderDialog.selectedFolder !== -1) {
      folderId = folders[docToFolderDialog.selectedFolder].id;
    }
    let document;
    if (docToFolderDialog.folderIndex === -1) {
      document = freeDocuments[docToFolderDialog.docIndex];
    } else {
      document =
        folderDocuments[folders[docToFolderDialog.folderIndex].id][
          docToFolderDialog.docIndex
        ];
    }

    let result = await updateUserDocument({ folder: folderId }, document.id);
    if (result.error) {
      showError(result.error);
    }
    if (
      docToFolderDialog.selectedFolder !== -1 &&
      !folders[docToFolderDialog.selectedFolder].open
    ) {
      toggleFolder(docToFolderDialog.selectedFolder)();
    }
    closeDocumentMenu();
    stopLoading("movingDoc");
    closeDocToFolderDialog();
    getUserDocuments();
  };

  const showError = (error) => {
    const { showSnackbar } = props;
    showSnackbar({
      text: error.message ? error.message : JSON.stringify(error),
      color: "error",
    });
  };

  const handleClickSignAction = async (file) => {
    if(config.partner === 'VNIQuk0to4ahhQyzgagz') {
      return openSecondSignatureForm()
    }else {
      if (!file.signStatus) return openSecondSignatureForm()
      if (file.signStatus === "requested") return signDocument()
      return downloadSignatureDocument()
    }
  };

  const renderClickSignActionText = (file) => {
    if (!file.signStatus) return t("mydocs.reqsign");
    if (file.signStatus === "requested") return config.partner === 'VNIQuk0to4ahhQyzgagz' ? t("mydocs.reqsign") : t("mydocs.sign");
    return t("mydocs.downloadDocument");
  };

  const getCurrentDocument = () => {
    let document;
    if (documentMenu.folderIndex === -1) {
      document = freeDocuments[documentMenu.index];
    } else {
      document =
        folderDocuments[folders[documentMenu.folderIndex].id][
          documentMenu.index
        ];
    }
    return document;
  };

  const downloadSignatureDocument = () => {
    const { files, name } = getCurrentDocument();
    const fileId = files["pdf"].signFileId;
    const url = `https://api.yousign.com${fileId}/download?alt=media`;
    const method = "GET";
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + "cbee315d3226e92a3ecf6b99d62ee67a",
    };
    return fetch(url, {
      method,
      headers,
    })
      .then((res) => res.blob())
      .then((blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error:", error));
  };

  const renderSignAction = () => {
    const document = getCurrentDocument();
    if (!document) {
      return null;
    }
    if (!!document.files && !!document.files.pdf && !!document.files.pdf.url) {
      return (
        <MenuItem onClick={() => handleClickSignAction(document.files.pdf)}>
          {renderClickSignActionText(document.files.pdf)}
        </MenuItem>
      );
    }else {
      return (
        <MenuItem disabled>
          {t("mydocs.reqsign")}
        </MenuItem>
      );
    }
  };

  const renderSignSecondForm = () => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openReqSecondSignatureForm}
      onEscapeKeyDown={closeSecondSignatureForm}
    >
      <div style={{ height: "100%" }}>
        <RequestSecondSignatureForm
          {...props}
          RequestSign={RequestSign}
          closeSecondSignatureForm={closeSecondSignatureForm}
        />
      </div>
    </Modal>
  );

  return (
    <div className="sec">
      <div className="container">
        <div
          data-duration-in="300"
          data-duration-out="100"
          className="tabs-2 w-tabs"
        >
          <div className="tabs-menu w-tab-menu">
            <a
              href="#documents"
              className={`tab__link w-inline-block w-tab-link ${
                currentTab === "documents" ? "w--current" : ""
              }`}
            >
              <div className="tab-link__title">{t("mydocs.documents")}</div>
              <div
                className={`tab-link__line ${
                  currentTab === "documents" ? "highlighted" : ""
                }`}
              ></div>
            </a>
            <a
              href="#drafts"
              className={`tab__link w-inline-block w-tab-link ${
                currentTab === "drafts" ? "w--current" : ""
              }`}
            >
              <div className="tab-link__title">{t("mydocs.drafts")}</div>
              <div
                className={`tab-link__line ${
                  currentTab === "drafts" ? "highlighted" : ""
                }`}
              ></div>
            </a>
          </div>
          <div className="w-tab-content">
            {currentTab === "documents" ? (
              <div className="tab-pane-tab-1">
                <div className="tab__title close-img" onClick={createNewFolder}>
                  {`+${t("mydocs.new_folder")}`}
                </div>
                <div className="tabs__cont-wrap tabs__cont-wrap--upper">
                  <div className="tab__title2">{t("mydocs.mydocs")}</div>
                  <div className="two-titles-wrap">
                    <div className="tab__title2 tab__title--right">
                      {t("mydocs.created")}
                    </div>
                    <div className="tab__title2 tab__title--right flex-end">
                      {t("mydocs.actions")}
                    </div>
                  </div>
                </div>
                {folders.map((folder, fi) => {
                  return (
                    <div key={`folder_${folder.name}`}>
                      <div
                        className="tabs__cont-wrap"
                        style={{
                          borderBottomWidth: folder.open ? 0 : 1,
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={toggleFolder(fi)}
                          className="link"
                          style={{ marginRight: 10 }}
                        >
                          {folder.open ? (
                            <FolderOpenIcon color="primary" />
                          ) : (
                            <FolderIcon color="primary" />
                          )}
                        </div>
                        <div
                          onClick={toggleFolder(fi)}
                          className="tab__title tab__title--black link"
                        >
                          {" "}
                          {folder.name}{" "}
                        </div>

                        <div className="two-titles-wrap">
                          <div className="tab__title2 tab__title--right-dots">
                            <div onClick={openFolderMenu(fi)} className="link">
                              ...
                            </div>
                          </div>
                        </div>
                      </div>
                      <Collapse in={folder.open}>
                        <div style={{ paddingLeft: "20px" }}>
                          {!!folderDocuments[folder.id] &&
                          folderDocuments[folder.id].length > 0 ? (
                            folderDocuments[folder.id].map((doc, di) => {
                              return (
                                <div
                                  key={`doc_${fi}${di}`}
                                  className="tabs__cont-wrap"
                                >
                                  <div className="tab__title tab__title--black">
                                    {doc.name}
                                  </div>
                                  <div className="two-titles-wrap">
                                    <div className="tab__title2 tab__title--right">
                                      <span className="t-span">
                                        {doc.meta && doc.meta.created && printDate(doc.meta.created)}
                                      </span>
                                      {doc.meta && doc.meta.created && printYear(doc.meta.created)}
                                    </div>
                                    <div className="tab__title2 tab__title--right-dots">
                                      <div
                                        onClick={openDocumentMenu(di, fi)}
                                        className="link"
                                      >
                                        ...
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="tab__title2 tab__title2--little">
                              {t("mydocs.folder_empty")}
                            </div>
                          )}
                        </div>
                      </Collapse>
                    </div>
                  );
                })}
                {freeDocuments.map((doc, di) => {
                  return (
                    <div key={`doc_${di}`} className="tabs__cont-wrap">
                      <div className="tab__title tab__title--black">
                        {doc.name}
                      </div>
                      <div className="two-titles-wrap">
                        <div className="tab__title2 tab__title--right">
                          <span className="t-span">
                            {doc.meta && doc.meta.created && printDate(doc.meta.created)}
                          </span>
                          {doc.meta && doc.meta.created && printYear(doc.meta.created)}
                        </div>
                        <div className="tab__title2 tab__title--right-dots">
                          <div
                            onClick={openDocumentMenu(di, -1)}
                            className="link"
                          >
                            ...
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <Menu
                  id="folder-menu"
                  anchorEl={folderMenu.anchor}
                  keepMounted
                  open={Boolean(folderMenu.anchor)}
                  onClose={closeFolderMenu}
                >
                  <MenuItem onClick={renameFolder}>
                    {t("general.rename")}
                  </MenuItem>
                  <MenuItem onClick={deleteFolder}>
                    {t("general.delete")}
                  </MenuItem>
                </Menu>

                <Menu
                  id="document-menu"
                  anchorEl={documentMenu.anchor}
                  keepMounted
                  open={Boolean(documentMenu.anchor)}
                  onClose={closeDocumentMenu}
                >
                  <MenuItem onClick={renameDocument}>
                    {t("general.rename")}
                  </MenuItem>
                  {documentMenu.index !== -1
                    ? formats.map((format, i) => {
                        let document;
                        if (documentMenu.folderIndex === -1) {
                          document = freeDocuments[documentMenu.index];
                        } else {
                          document =
                            folderDocuments[
                              folders[documentMenu.folderIndex].id
                            ][documentMenu.index];
                        }

                        if (!!document.files && !!document.files[format]) {
                          let downloadEl = null;
                          if (
                            document.files[format].generation_progress === 1
                          ) {
                            downloadEl = (
                              <MenuItem
                                key={`doc_downloadtem_${i}`}
                                onClick={downloadDocument(format)}
                              >
                                {t("mydocs.download", { format })}
                              </MenuItem>
                            );
                          } else if (
                            document.files[format].generation_progress < 1
                          ) {
                            downloadEl = (
                              <MenuItem key={`doc_downloadtem_${i}`} disabled>
                                {t("mydocs.preparing", { format })}
                              </MenuItem>
                            );
                          }

                          return downloadEl;

                          // return [downloadEl, signEl];
                        } else {
                          return null;
                        }
                      })
                    : null}

                  {config.signature_enabled && renderSignAction()}
                  {/* if (!document.signStatus) {
                        signEl = (
                          <MenuItem
                            key={`doc_reqsign_${i}`}
                            onClick={RequestSign}
                          >
                            {t("mydocs.reqsign")}
                          </MenuItem>
                        );
                      } else if (
                        document.files[format].signStatus === "requested"
                      ) {
                        signEl = (
                          <MenuItem
                            key={`doc_sign_${i}`}
                            onClick={signDocument}
                          >
                            {t("mydocs.sign")}
                          </MenuItem>
                        );
                      }
                    }
                  } */}

                  <MenuItem
                    disabled={folders.length === 0}
                    onClick={promptMoveDocToFolder}
                  >
                    {t("mydocs.move_to")}
                  </MenuItem>
                  <MenuItem onClick={deleteDocument}>
                    {t("general.delete")}
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div className="tab-pane-tab-2">
                <div className="tabs__cont-wrap tabs__cont-wrap--upper">
                  <div className="tab__title2">{t("mydocs.mydrafts")}</div>
                  <div className="two-titles-wrap">
                    <div className="tab__title2 tab__title--right">
                      {t("mydocs.created")}
                    </div>
                    <div className="tab__title2 tab__title--right">
                      {t("mydocs.actions")}
                    </div>
                  </div>
                </div>
                {drafts.length > 0 ? (
                  drafts.map((draft, di) => {
                    return (
                      <div key={draft.id} className="tabs__cont-wrap">
                        <div className="tab__title tab__title--black">
                          {draft.name}
                        </div>
                        <div className="two-titles-wrap">
                          <div className="tab__title2 tab__title--right">
                            <span className="t-span">
                              {draft.created && printDate(draft.created)}
                            </span>
                            {draft.created && printYear(draft.created)}
                          </div>
                          <div className="tab__title2 tab__title--right-dots">
                            <div onClick={openDraftMenu(di)} className="link">
                              ...
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="tab__title2 tab__title2--little">
                    {t("mydocs.drafts_empty")}
                  </div>
                )}
                <Menu
                  id="draft-menu"
                  anchorEl={draftMenu.anchor}
                  keepMounted
                  open={Boolean(draftMenu.anchor)}
                  onClose={closeDraftMenu}
                >
                  <MenuItem onClick={continueDraft}>
                    {t("mydocs.complete_draft")}
                  </MenuItem>
                  <MenuItem onClick={deleteDraft}>
                    {t("general.delete")}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={folderDialog.isOpen}
        onEscapeKeyDown={closeFolderDialog}
      >
        <div className={`pop-up pop-up--${config.class_handle}`}>
          <div className="pop__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">
                {folderDialog.isNew
                  ? t("mydocs.new_folder")
                  : t("mydocs.rename_folder")}
              </h1>
              <div onClick={closeFolderDialog}>
                <img
                  src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "images/x-2-beci.svg" : "images/x-2.svg"}
                  alt="close-icon"
                  data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e"
                  className="close-img"
                />
              </div>
            </div>
            <div className="pop__form-wrap">
              <div className="w-form">
                <div id="email-form" name="email-form" data-name="Email Form">
                  <label htmlFor="email" className="pop__form-label">
                    {t("mydocs.folder_name")}
                  </label>
                  <input
                    value={folderDialog.name}
                    onChange={(e) => {
                      setFolderDialog({
                        ...folderDialog,
                        name: e.target.value,
                      });
                    }}
                    type="text"
                    className="pop__text-field w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    id="email"
                    required=""
                  />
                  <div className="btn-wrap">
                    <div onClick={closeFolderDialog} className="btn-line-wrap">
                      <div className="pop-up__btn btn--full w-button">
                        {t("general.cancel")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                    <div onClick={submitFolderDialog} className="btn-line-wrap">
                      <div className="pop-up__btn btn--full w-button">
                        {folderDialog.isNew
                          ? t("mydocs.create_folder")
                          : t("general.save")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={docToFolderDialog.isOpen}
        onEscapeKeyDown={closeDocToFolderDialog}
      >
        <div className={`pop-up pop-up--${config.class_handle}`}>
          <div className="pop__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">{t("mydocs.select_folder")}</h1>
              <div onClick={closeDocToFolderDialog}>
                <img
                  src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "images/x-2-beci.svg" : "images/x-2.svg"}
                  alt="close-icon"
                  data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e"
                  className="close-img"
                />
              </div>
            </div>
            <div className="pop__form-wrap">
              <div className="w-form">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={docToFolderDialog.selectedFolder}
                    onChange={(e) =>
                      setDocToFolderDialog({
                        ...docToFolderDialog,
                        selectedFolder: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={-1}>{t("mydocs.no_folder")}</MenuItem>
                    {folders.map((folder, fi) => {
                      return (
                        <MenuItem key={`folder_option_${fi}`} value={fi}>
                          {folder.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div className="btn-wrap">
                  <div
                    onClick={closeDocToFolderDialog}
                    className="btn-line-wrap"
                  >
                    <div className="pop-up__btn btn--full w-button">
                      {t("general.cancel")}
                    </div>
                    <div className="red-line"></div>
                  </div>
                  <div onClick={moveDocToFolder} className="btn-line-wrap">
                    <div className="pop-up__btn btn--full w-button">
                      {t("general.confirm")}
                    </div>
                    <div className="red-line"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* {signMember !== null ? (
              <iframe
                src={
                  STAGING_APP_BASE_URL + "/procedure/sign?members=" + signMember
                }
                width="512"
                height="512"
              />
            ) : null} */}
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={documentDialog.isOpen}
        onEscapeKeyDown={closeDocumentDialog}
      >
        <div className={`pop-up pop-up--${config.class_handle}`}>
          <div className="pop__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">{"Rename document"}</h1>
              <div onClick={closeDocumentDialog}>
                <img
                  src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "images/x-2-beci.svg" : "images/x-2.svg"}
                  alt="close-icon"
                  data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e"
                  className="close-img"
                />
              </div>
            </div>
            <div className="pop__form-wrap">
              <div className="w-form">
                <div id="email-form" name="email-form" data-name="Email Form">
                  <label htmlFor="email" className="pop__form-label">
                    {t("mydocs.document_name")}
                  </label>
                  <input
                    value={documentDialog.name}
                    onChange={(e) => {
                      setDocumentDialog({
                        ...documentDialog,
                        name: e.target.value,
                      });
                    }}
                    type="text"
                    className="pop__text-field w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    id="email"
                    required=""
                  />
                  <div className="btn-wrap">
                    <div
                      onClick={closeDocumentDialog}
                      className="btn-line-wrap"
                    >
                      <div className="pop-up__btn btn--full w-button">
                        {t("general.cancel")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                    <div
                      onClick={submitDocumentDialog}
                      className="btn-line-wrap"
                    >
                      <div className="pop-up__btn btn--full w-button">
                        {t("general.save")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {renderSignSecondForm()}
    </div>
  );
};

// const formats = ['pdf', 'odt', 'docx']
const formats = ["docx", "pdf"];

const printDate = (date) => {
  return moment(date).format("DD MMM");
};

const printYear = (date) => {
  return moment(date).format("YYYY");
};

export default MyDocuments;
