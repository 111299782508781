import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, checkVerificationCode } from '../services/auth'
import { createUser } from '../services/database'
import moment from 'moment'
import { t, currentLanguage, validatePhoneNumber } from '../utils'
import config from '../config.json'
import * as functions from '../services/functions'
// #00a8ff

const Auth = (props) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [verificationPassword, setVerificationPassword] = useState('')
  const [resetPassword, setResetPassword] = useState(false)
  const [register, setRegister] = useState(false)
  const [code, setCode] = useState('')
  const [conditionsChecked, setConditionsChecked] = useState({})
  const [enterpriseNumber, setEnterpriseNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const { startLoading, stopLoading, showSnackbar, onLogin, onPasswordReset, onRegister, getUser, onClose, updateUserType } = props


  const onChangeCode = (e) => {
    setCode(e.target.value)
  }

  const submit = async () => {
    if (!email || email === '') {
      showSnackbar({ text: t('auth.empty_email'), color: 'error' })
      return
    }
    if (!resetPassword && (!password || password === '')) {
      showSnackbar({ text: t('auth.empty_password'), color: 'error' })
      return
    }
    if (register) {
      if (!firstName || firstName === '') {
        showSnackbar({ text: t('auth.empty_name_first'), color: 'error' })
        return
      } else if (!lastName || lastName === '') {
        showSnackbar({ text: t('auth.empty_name_last'), color: 'error' })
        return
      }
      if (password !== verificationPassword) {
        showSnackbar({ text: t('auth.password_mismatch'), color: 'error' })
        return
      }
      if (requireConditions() && !areConditionsAgreed()) {
        showSnackbar({ text: t('auth.accept_required'), color: 'warning' })
        return
      }
      if(config.requires_verification_code) {
        if (!code) {
          showSnackbar({ text: t('auth.code_mismatch'), color: 'error' })
          return
        }        
      }
      if(config.requires_enterprise_number) {
        if (!enterpriseNumber) {
          showSnackbar({ text: t('auth.enterprise_number_required'), color: 'error' })
          return
        }    
        if(enterpriseNumber.length < 10 || enterpriseNumber.length > 11) {
          showSnackbar({ text: t('auth.vat_invalid'), color: 'error' })
          return
        }    
      }
      if(config.requires_company_name) {
        if (!companyName) {
          showSnackbar({ text: t('auth.company_name_required'), color: 'error' })
          return
        }        
      }
      if(config.requires_phone_number) {
        if (!phoneNumber) {
          showSnackbar({ text: t('auth.phone_number_required'), color: 'error' })
          return
        }        
        if (!validatePhoneNumber(phoneNumber)) {
          showSnackbar({ text: t('auth.phone_number_invalid'), color: 'error' })
          return
        }        
      }
    }
    const loadingKey = 'loadingAuth'
    startLoading(loadingKey)
    let result
    if (register && config.requires_verification_code) {
      const response = await checkVerificationCode(code, config.multiple_user_types ? config.partner : null)
      if (!response.valid) {
        stopLoading(loadingKey)
        showSnackbar({ text: t('auth.wrong_code'), color: 'error' })
        return
      }
    }
    if (resetPassword) {
      result = await sendPasswordResetEmail(email)
    } else if (register) {
      result = await createUserWithEmailAndPassword(email, password, config.multiple_user_types ? code : '')
    } else {
      result = await signInWithEmailAndPassword(email, password)
    }
    if (result.error) {
      stopLoading(loadingKey)
      showSnackbar({ text: t(`firebase_error.${result.error.code}`), color: 'error' })
      return
    } else if (!result.error && register) {
      let userData = {
        name_first: firstName,
        name_last: lastName,
        email: email,
        meta: {
          registered: moment().valueOf()
        }
      }
      if(config.requires_enterprise_number) {
        userData.vat = enterpriseNumber.trim()
      }
      if(config.requires_company_name) {
        userData.company_name = companyName.trim()
      }
      if(config.requires_phone_number) {
        userData.phone = phoneNumber.trim()
      }
      result = await createUser(userData)
      if (!result.error) {
        showSnackbar({ text: t('auth.account_created'), color: 'success' })
        stopLoading(loadingKey)
        onRegister()
        getUser()
        if(config.multiple_user_types) {
          updateUserType()
        }
        if(config.partner === 'tKQgHPEaRHz1bMAlCBzk') {
          let emailHtml = `
            <h2 style="margin-bottom:20px;font-size:32px;color:#1B1D24;">${t('welcome_email.title')}</h2>
            <p>${t('welcome_email.message')}</p>
            <a href="${window.location.origin}" style="display:inline-block;background-color:#2800d7;color:#fff;cursor:pointer;text-decoration:none;border-radius:3px;text-align:center;font-size:14px;border-top:10px solid #2800d7;border-bottom:10px solid #2800d7;border-left:20px solid #2800d7;border-right:20px solid #2800d7;">${t('welcome_email.btn_text')}</a>
          `;
          let data = {
            recipient: email,
            emailHtml,
            subject: t('welcome_email.subject')
          }
          functions.sendWelcomeEmail(data)
        }
        return
      } else {
        stopLoading(loadingKey)
        showError(result.error.message)
        return
      }
    } else {
      if(config.multiple_user_types) {
        updateUserType()
      }
    }
    stopLoading(loadingKey)
    if (resetPassword) {
      showSnackbar({ text: t('auth.reset_success'), color: 'success' })
      onPasswordReset()
    } else {
      onLogin()
    }
  }

  const areConditionsAgreed = () => {
    for (let i in config.auth_conditions) {
      if (!conditionsChecked[config.auth_conditions[i]]) {
        return false
      }
    }
    return true
  }

  const title = () => {
    if (register) {
      return t('auth.no_account')
    } else if (resetPassword) {
      return t('auth.forgot_password')
    } else {
      return t('auth.got_account')
    }
  }

  const description = () => {
    if (register) {
      return t('auth.no_account_text')
    } else {
      return t('auth.got_account_text')
    }
  }

  const sideTitle = () => {
    if (register) {
      return t('auth.got_account')
    } else {
      return t('auth.no_account')
    }
  }

  const sideButtonTitle = () => {
    if (register) {
      return t('auth.sign_in')
    } else {
      return t('auth.create_account')
    }
  }

  const sideDescription = () => {
    if (register) {
      return t('auth.got_account_text')
    } else {
      return t('auth.no_account_text')
    }
  }

  const sideButtonOnClick = () => {
    if (register) {
      setResetPassword(false)
      return setRegister(false)
    } else {
      setResetPassword(false)
      return setRegister(true)
    }
  }

  const showError = (message) => {
    const { showSnackbar } = props
    showSnackbar({ text: message, color: 'error' })
  }

  const requireConditions = () => {
    return !!config.auth_conditions && config.auth_conditions.length > 0
  }

  const openDoc = (docName) => () => {
    window.open(`/docs/${docName}(${currentLanguage()}).pdf`)
  }

  const renderCheckboxLabel = (text, linkText, doc) => {
    let components = text.split('%{link}')
    if (components.length === 1) {
      return <span className="checkbox-label-2 w-form-label">{components[0]}<span className="auth-doc-link" onClick={openDoc(doc)} >{linkText}</span></span>
    } else if (components.length === 2) {
      return <span className="checkbox-label-2 w-form-label">{components[0]}<span className="auth-doc-link" onClick={openDoc(doc)} >{linkText}</span>{components[1]}</span>
    }
  }

  const setConditionChecked = (cond, checked) => {
    let c = conditionsChecked
    c[cond] = checked
    setConditionsChecked({ ...c })
  }

  return (
    <div className={`auth-dialog--${config.class_handle}`} style={{ backgroundColor: '#FFF', display: 'flex', flexDirection: 'column' }}>
      { props.title ? (

        <div className="pop__title-wrap">
          <h1>{props.title}</h1>
          <div onClick={onClose}>
            {config.partner === 'tKQgHPEaRHz1bMAlCBzk' 
              ? <img src="/images/x-2-beci.svg" alt="close-icon" data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e" className="close-img" />
              : <img src="/images/x-2.svg" alt="close-icon" data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e" className="close-img" />
            }
          </div>
        </div>
      ) : null}
      <div className="first-row first-row--l" style={{ marginBottom: 0 }} >
        <div className="col col--l">
          <div className="l-sec__cont">
            <h1 className="l-sec__h">{sideTitle()}</h1>
            {/* { !resetPassword ? (
            <p className="l-sec__p">{ sideDescription() }</p>
            ) : null } */}
            <div onClick={sideButtonOnClick} className="btn btn--full w-inline-block">
              <h4 className="nav__h nav__h--white">{sideButtonTitle()}</h4>
            </div>
          </div>
        </div>
        <div className="col col--l">
          <div className="l-sec__cont l-sec__cont--white">
            <h1 className="l-sec__h">{title()}</h1>
            {/* <p className="l-sec__p">{ description() }</p> */}
            <form className="l-sec__form w-form">
              <div id="email-form" name="email-form" data-name="Email Form">
                <Collapse in={register}>
                  <label htmlFor="firstname" className="form__label">{t('general.name_first')}</label>
                  <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" className="form__field w-input" maxLength="256" name="firstname" data-name="FirstName" id="firstname" />

                  <label htmlFor="lastname" className="form__label">{t('general.name_last')}</label>
                  <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" className="form__field w-input" maxLength="256" name="lastname" data-name="LastName" id="lastname" />
                </Collapse>
                <label htmlFor="name" className="form__label">{t('general.email')}</label>
                <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="form__field w-input" maxLength="256" name="name" data-name="Name" id="name" />
                <Collapse in={!resetPassword || register}>
                  <label htmlFor="Password" className="form__label">{t('general.password')}</label>
                  <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form__field w-input" maxLength="256" name="Password" data-name="Password" id="Password" />
                </Collapse>
                <Collapse in={register}>
                  <label htmlFor="password2" className="form__label">{t('auth.confirm_password')}</label>
                  <input type="password" value={verificationPassword} onChange={e => setVerificationPassword(e.target.value)} className="form__field w-input" maxLength="256" name="password2" data-name="Password2" id="password2" />

                  {requireConditions() ? (
                    <div>
                      { config.auth_conditions.map(cond => {
                        return (
                          <label className="w-checkbox auth-checkbox">
                            <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox-3 ${conditionsChecked[cond] ? 'w--redirected-checked' : ''}`}></div>
                            <input checked={conditionsChecked[cond]} onChange={e => setConditionChecked(cond, e.target.checked)} type="checkbox" id="checkbox-privacy" name="checkbox" data-name="Checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
                            { renderCheckboxLabel(t(`auth.accept_${cond}`), t(`auth.${cond}`), cond)}
                          </label>
                        )
                      })}
                    </div>
                  ) : null}
                </Collapse>
                <Collapse in={(config.requires_verification_code || config.pro_users) && register}>
                  <label htmlFor="code" className="form__label">{t('general.code')}</label>
                  <input value={code} onChange={onChangeCode} type="text" className="form__field w-input" name="code" data-name="code" id="code" />
                </Collapse>
                <Collapse in={config.requires_enterprise_number && register}>
                  <label htmlFor="enterpriseNumber" className="form__label">{t('auth.enterprise_number')}</label>
                  <input value={enterpriseNumber} onChange={(e) => setEnterpriseNumber(e.target.value)} type="number" className="form__field w-input" name="enterpriseNumber" data-name="enterpriseNumber" id="enterpriseNumber" />
                </Collapse>
                <Collapse in={config.requires_company_name && register}>
                  <label htmlFor="companyName" className="form__label">{t('auth.company_name')}</label>
                  <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} type="text" className="form__field w-input" name="companyName" data-name="companyName" id="companyName" />
                </Collapse>
                <Collapse in={config.requires_phone_number && register}>
                  <label htmlFor="phoneNumber" className="form__label">{t('auth.phone_number')}</label>
                  <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="text" className="form__field w-input" name="phoneNumber" data-name="phoneNumber" id="phoneNumber" placeholder="+XXXXXXXXXXX" />
                </Collapse>
                {!register ? (
                  <h6 className="little-h reset-password-btn" onClick={() => { setResetPassword(!resetPassword) }}>{resetPassword ? t('auth.sign_in') : t('auth.forgot_password')}</h6>
                ) : null}
              </div>
            </form>
            <div onClick={submit} className="btn btn--full2 w-inline-block">
              <h4 className="nav__h nav__h--white">{register ? t('auth.create_account') : resetPassword ? t('auth.send_reset') : t('auth.sign_in')}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
