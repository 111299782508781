import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Footer, PartnersFooter } from "../components";

import { isUserSignedIn, signOut } from "../services/auth";

import { t, languageFromLocale, currentLanguage, ReactGA, currentLocale } from "../utils";

import config from "../config.json";
import { Button } from "@material-ui/core";

const routesWithoutPartners = ["/", "/fr", "/nl", "/pl", "/en", "/login"];

const NavigationPage = (props) => {
  const [currentPath, setCurrentPath] = useState("/");
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null)

  useEffect(() => {
    props.history.listen((location) => {
      const { pathname } = location;
      setCurrentPath(pathname);
    });
  }, []);

  useEffect(() => {
    const { pathname } = props.history.location;
    setCurrentPath(pathname);
    ReactGA.pageview(props.history.location.pathname);

    props.refreshInteractions();
  }, [props.history.location.pathname]);

  const navigate = (path) => () => {
    const { history } = props;
    history.push(path);
    setCurrentPath(path);
  };

  const openAccountMenu = (e) => {
    setAccountMenuAnchor(e.target);
  };

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null);
  };

  const doSignOut = async () => {
    const { startLoading, stopLoading } = props;
    startLoading("signingOut");
    await signOut();
    stopLoading("signingOut");
    closeAccountMenu();
    navigate("/")();
  };

  const openProfile = () => {
    navigate("/myprofile")();
    closeAccountMenu();
  };

  const openMyDocuments = () => {
    navigate("/mydocuments")();
    closeAccountMenu();
  };

  const shouldShowPartners = () => {
    const { pathname } = props.history.location;
    return (
      !!config.partners &&
      config.partners.length > 0 &&
      routesWithoutPartners.indexOf(pathname) === -1
    );
  };

  const { signedIn } = props;

  const shouldRenderLocaleSelection = () => {
    let languages = []
    for(let i in config.locales) {
      let lang = languageFromLocale(config.locales[i]).code
      if(languages.includes(lang)) {
        return true
      } 
      languages.push(lang)
    }
    return false
  }
  

  const openLanguageMenu = (e) => {
    setLanguageMenuAnchor(e.target);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuAnchor(null);
  };

  const selectLocale = (locale) => () => {
    props.setLocale(locale)
    closeLanguageMenu()
  }

  const renderLocaleLabel = (locale) => {
    const language = languageFromLocale(locale).long
    const country = locale.split('_')[1].toLowerCase()
    return (
      <div className="locale-label-wrapper">
        <img src={`/images/flag_${country}.png`} className="locale-label-image" alt={`${country} flag`} />
        <span className="locale-label">{ language }</span>
      </div>
    )
  }

  const renderLanguageSelection = () => {
    if(config.locales.length > 1) {
      if(shouldRenderLocaleSelection()) {
        return (
          <>
          <Button onClick={openLanguageMenu}>{ renderLocaleLabel(currentLocale()) }</Button>
          <Menu open={Boolean(languageMenuAnchor)} onClose={closeLanguageMenu} keepMounted anchorEl={languageMenuAnchor}>
            { config.locales.map((locale, li) => {
              return (
                <MenuItem key={`locale_option_${li}`} dense onClick={selectLocale(locale)}>{ renderLocaleLabel(locale) }</MenuItem>
              )
            }) }
          </Menu>
          </>
        )
      } else {
        return config.locales.map((locale, li) => {
          return (
            <div
              key={`locale_${li}`}
              onClick={selectLocale(locale)}
              className={`nav-link--lang w-nav-link ${
                languageFromLocale(locale).code === currentLanguage()
                  ? "highlighted"
                  : ""
              }`}
            >
              {languageFromLocale(locale).code}
            </div>
          );
        })
      }
    }
    return null
  }

  return (
    <div className="sec-wrap">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0a"
        className="navbar w-nav"
      >
        <div className="container">
          <div className="nav-wrap">
            <div onClick={navigate("/")} className="brand-link w-nav-brand">
              {config.env !== "development" ? (
                <img
                  src="/images/logo_large.png"
                  className="navbar-logo"
                  alt=""
                />
              ) : null}
            </div>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0f"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/")}
                  className={`nav-link nav-link--${config.class_handle} w-nav-link${
                    currentPath === "/" ? " w--current" : ""
                  }`}
                >
                  {t("nav.home")}
                </div>
                <div className={`nav-line nav-line--${config.class_handle}`}></div>
              </div>
              {!config.single_document ? (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa13"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/documents")}
                    className={`nav-link nav-link--${config.class_handle} w-nav-link${
                      currentPath === "/documents" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.documents")}
                  </div>
                  <div className={`nav-line nav-line--${config.class_handle}`}></div>
                </div>
              ) : null}
              {/* {!config.single_document ? (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa17"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/authors")}
                    className={`nav-link w-nav-link${
                      currentPath === "/authors" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.authors")}
                  </div>
                  <div className="nav-line"></div>
                </div>
              ) : null} */}
              {config.authors && config.authors.length > 0 && (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa17"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/authors")}
                    className={`nav-link nav-link--${config.class_handle} w-nav-link${
                      currentPath === "/authors" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.authors")}
                  </div>
                  <div className="nav-line"></div>
                </div>
              )}
              { config.has_issue_report ? (

                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                  className="nav-link-wrap"
                  >
                    <div
                      onClick={navigate("/report")}
                      className={`nav-link nav-link--${config.class_handle} w-nav-link${
                        currentPath === "/report" ? " w--current" : ""
                      }`}
                    >
                      {t("nav.report_issue")}
                    </div>
                  <div className="nav-line"></div>
                </div>
              ) : null }
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/contact")}
                  className={`nav-link nav-link--${config.class_handle} w-nav-link${
                    currentPath === "/contact" ? " w--current" : ""
                  }`}
                >
                  {t("nav.contact")}
                </div>
                <div className={`nav-line nav-line--${config.class_handle}`}></div>
              </div>
            </nav>
            <div className="nav-link-wrap--lang">
              { (!config.requires_auth || isUserSignedIn()) &&
                <div
                  onClick={signedIn ? openAccountMenu : navigate("/login")}
                  className="nav-btn btn btn--red w-inline-block"
                >
                  <h4 className="nav__h">
                    {signedIn ? t("general.my_account") : t("nav.connect")}
                  </h4>
                  <div className="growing-div"></div>
                </div>
              }
              { renderLanguageSelection() }
            </div>
            <div className="menu-btn w-nav-button">
              <img
                src="/images/burger_menu.svg"
                alt="burger icon"
                className="burger-icon"
              />
              <img src="/images/x-2.svg" alt="" className="close-icon" />
            </div>
          </div>
        </div>
        <div className="nav__g-line"></div>
        <Menu
          id="add-question-menu"
          anchorEl={accountMenuAnchor}
          keepMounted
          open={Boolean(accountMenuAnchor)}
          onClose={closeAccountMenu}
        >
          <MenuItem dense onClick={openProfile}>
            {t("nav.my_profile")}
          </MenuItem>
          <MenuItem dense onClick={openMyDocuments}>
            {t("nav.my_documents")}
          </MenuItem>
          <MenuItem dense onClick={doSignOut}>
            {t("nav.sign_out")}
          </MenuItem>
        </Menu>
      </div>
      {props.children}
      {shouldShowPartners() ? <PartnersFooter /> : null}
      <Footer navigate={navigate} />
    </div>
  );
};

export default NavigationPage;
