import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
// import Route from './Route'
import {
  Home,
  Documents,
  DocumentForm,
  DocumentFormV2,
  DocumentDetail,
  Authors,
  Login,
  Profile,
  MyDocuments,
  Contact,
  SingleDocHome,
  Subscriptions,
  Payment,
  PaymentSuccess
} from '../pages'

import config from '../config.json'

const defaultUnauthorizedPath = '/login'
const defaultAuthorizedPath = '/'


const privatePaths = () => {
  let pp = [
    '/myprofile',
    '/mydocuments',
    '/payment',
    '/payment-success'
  ]
  if(config.requires_auth) {
    pp.push('/')
    pp.push('/documents')
    pp.push('/document-form')
    pp.push('/authors')
    pp.push('/contact')
    pp.push('/report')
  }
  return pp
}

const onlyUnauthorizedPaths = [
  '/login',
  '/register'
]

const isPathPrivate = (path) => {
  return privatePaths().indexOf(path) !== -1
}

const isPathOnlyUnauthorized = (path) => {
  return onlyUnauthorizedPaths.indexOf(path) !== -1
}

const Routes = (props) => {
  return (
    <Switch>
      <Route {...props} path="/documents" render={(p) => <Documents {...p} {...props} />} isPrivate={isPathPrivate('/documents')} />
      <Route {...props} path="/document/:id" render={(p) => <DocumentDetail {...p} {...props} />} isPrivate={isPathPrivate('/document')} />
      <Route {...props} path="/document-form/:id" render={(p) => config.uses_v2 ? <DocumentFormV2 {...p} {...props} /> : <DocumentForm {...p} {...props} />} isPrivate={isPathPrivate('/document-form')} />
      {config.authors && config.authors.length > 0 ? <Route {...props} path="/authors" render={(p) => <Authors {...p} {...props} />} isPrivate={isPathPrivate('/authors')} /> : null} 
      <Route {...props} path="/login" render={(p) => <Login {...p} {...props} />} isPrivate={isPathPrivate('/login')} />
      <Route {...props} path="/myprofile" render={(p) => <Profile {...p} {...props} />} isPrivate={isPathPrivate('/myprofile')} />
      <Route {...props} path="/mydocuments" render={(p) => <MyDocuments {...p} {...props} />} isPrivate={isPathPrivate('/mydocuments')} />
      <Route {...props} path="/contact" render={(p) => <Contact {...p} {...props} />} isPrivate={isPathPrivate('/contact')} />
      <Route {...props} path="/report" render={(p) => <Contact {...p} {...props} report={true} />} isPrivate={isPathPrivate('/report')} />
      { config.requires_payment ? (
        <Route {...props} path="/payment" render={(p) => <Payment {...p} {...props} />} isPrivate={isPathPrivate('/payment')} />
      ) : null }
      { config.requires_payment ? (
        <Route {...props} path="/payment-success" render={(p) => <PaymentSuccess {...p} {...props} />} isPrivate={isPathPrivate('/payment-success')} />
      ) : null } 
      { config.requires_payment ? (
        <Route {...props} path="/subscription" render={(p) => <Subscriptions {...p} {...props} />} isPrivate={isPathPrivate('/subscription')} />
      ) : null } 
      <Route {...props} path="/" render={(p) => config.single_document ? <SingleDocHome {...p} {...props} /> : <Home {...p} {...props} />} isPrivate={isPathPrivate('/')}  />
      <Route render={() => (
        <Redirect to="/"/>
      )}/>
      {/* <Route {...props} component={Home} isPrivate={isPathPrivate('/')} isNavigatorPage={isNavigatorPage('/')} pages={navigatorPages} /> */}
      {/* <Route {...props} component={() => <Redirect to={'/'} /> } /> */}
    </Switch>
  )
}

export default Routes

export {
  defaultAuthorizedPath,
  defaultUnauthorizedPath,
  isPathPrivate,
  isPathOnlyUnauthorized
}